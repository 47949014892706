

.App {
  width: 100%;
  text-align: center;
  display: flex;
  background-image: url("/public/images/background2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'Quicksand', sans-serif;
}



.nav {
  background-color:rgba(0,0,20,0.2);
  backdrop-filter:blur(40px);
  position: fixed;
}



.wdo {
  border: 20px solid transparent;
  border-image: url("/public/images/wdblock.svg") 30 stretch;
  min-width: 250px;
  max-width: 750px;

}
.wdo2 {
  border: 20px solid transparent;
  border-image: url("/public/images/wdblock.svg") 30 stretch;
  min-width: 250px;
  
}

.contact {
  border: 25px solid transparent;
  border-image: url("/public/images/wdblock.svg") 30 stretch;
  min-width: 440px;
  max-width: 530px;
  padding: 10px;
}

.try{
  margin-left:calc(100% / 3);
}

.projectsct{
  background-image: url("/public/images/projectbg.png");
  background-repeat: no-repeat;
  background-size:  contain;
  padding: 30px;
  width:250px;
  max-width: 250px;
}
.projectsct2{
  background-image: url("/public/images/projectbg.png");
  background-repeat: no-repeat;
  background-size:  contain;
  padding: 30px;
  width:250px;
  max-width: 250px;
  min-height: 250px;
}

.navloc {
  margin-left: calc(100% / 12);
}


.partnerbg{
  background-image: url("/public/images/partners.png");
  background-repeat: no-repeat;
  background-size:  contain;
  padding: 35px;
  padding-bottom: 12%;
  width:295px;
  height:180px;
 
}

.btn{
  background-color: transparent;
  border: 20px solid transparent;
  border-image: url("/public/images/wdblock.svg") 20 stretch;
}

.cbtn{
  background-color: transparent;
  border: 10px solid transparent;
  border-image: url("/public/images/wdblock.svg") 17 stretch;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
